export const id = {
    general: {
        send: "Kirim",
        withdraw: "Ambil",
        save: "Simpan",
        logout: "Keluar",
        logout_notice: "Keluar dari Remid",
        statement1: "Dengan mendaftarkan akun, Saya setuju dengan",
        statement2: " Syarat dan Ketentuan ",
        statement3: "berlaku.",
        signup_header: "Verifikasi Berhasil",
        signup_subheader: "Akun Anda telah berhasil dibuat. Masuk untuk mulai menggunakan Remid.",
        form_signup1: "Buat Akun Sekarang!",
        form_signup2: "Daftar untuk menggunakan Remid",
        form_signup3: "Sudah memiliki Akun?",
        form_moneytf1: "Formulir Pengiriman Uang",
        form_moneytf2: "Nama Lengkap Penerima",
        form_moneytf3: "No. Identitas Penerima",
        form_moneytf4: "No. Handphone Penerima",
        form_moneytf5: "Jumlah uang yang dikirim",
        form_moneytf6: "Sumber Dana Pengirim",
        form_moneytf7: "Tujuan transaksi pengiriman",
        form_chngphone1: "No. Handphone Baru",
        overlay1: "Konfirmasi Data Penerima",
        overlay2: "Pastikan Data Penerima, Nama, No Identitas/KTP dan No Handphone sudah sesuai",
        overlay3: "Sesi Anda Telah Habis",
        overlay4: "Silakan login kembali untuk melanjutkan aksi Anda.",
        title1: "Ubah Email",
        title2: "Ubah No. Handphone",
        title3: "Ubah Pin",
        title4: "Atur Pin",
        title5: "Verifikasi",
        btn1: "UBAH",
        header1: "Berhasil Diubah!",
        header2: "Berhasil Diubah!",
        subheader1: "Selamat nomor handphone Anda berhasil diubah",
        subheader2: "Selamat kata sandi Anda berhasil diubah!",
        subheader3: "Selamat PIN Anda berhasil diubah!",
        subheader4: "Selamat PIN Anda berhasil Dibuat!",
        showqr: "Tunjukkan QR Code kepada petugas Alfamart",
        close: "TUTUP",
        general_error: "Kesalahan Umum",
        register_error: "Kesalahan Pendaftaran",
        failed1: "Gagal",
        failed2: "Untuk melakukan transaksi berikutnya mohon tunggu sampai akun Anda terverifikasi.",
    },
    auth: {
        email: "Email",
        phone: "No. Handphone",
        username: "No. Handphone/Email",
        password: "Kata Sandi",
        old_password: "Kata Sandi Lama",
        forgot_password: "Lupa Kata Sandi",
        sign_in: "Masuk",
        helper_text_password: "Minimal 8 Karakter termasuk kombinasi huruf (A-z), Angka (0-9), dan simbol ",
        register: "Daftar",
        confirm_password: "Konfirmasi Kata Sandi",
        header: "Selamat Datang!",
        sub_header: "Masuk untuk menggunakan Remid",
        do_not_have_account: "belum memiliki Akun?",
        success_sign_in_header: "Berhasil Masuk",
        success_sign_in_subheader: 'Selamat Anda berhasil masuk. Klik "Lanjut" untuk masuk ke halaman utama',
        next_text: "Lanjut",
        cancel: "Batal",
        back: "KEMBALI",
        success: "Berhasil",
        helper_text_password2: "Minimal 8 karakter",
        helper_text_password3: "Atur Kata Sandi Baru",
        news: "Ingin tahu update Remid?",
        news_detail: "Lihat Disini",
    },
    home: {
        header: "Selamat Datang!",
        sub_header: "Cobain semua fitur remid sekarang juga."
    },
    bottom_navigation: {
        home: "Beranda",
        transaction: "Transaksi",
        profile: "Profil",
        version: "Versi 1.0.0",
    },
    operations: {
        operation: "Fitur Produk",
        send_money: "Pengiriman Uang",
        withdraw_money: "Pengambilan Uang",
        cancel_money: "Pembatalan Uang"
    },
    validation: {
        email_format: "Format email salah, contoh: rem@remid.com",
        phone_required: "Nomor Handphone harus diisi",
        phone_format: "Format nomor telepon tidak valid",
        phone_short: "Nomor terlalu pendek, minimal 10 karakter",
        phone_long: "Nomor terlalu panjang, maksimal 14 karakter",
        password_short: "Minimum 8 karakter",
        password_combination: "Kombinasi huruf dan angka",
        password_required: "Kata sandi wajib diisi",
        password_match: "Kata sandi dan konfirmasi kata sandi sesuai",
        name_required: "Nama harus diisi",
        identity_number_required: "No. identitas harus diisi",
        identity_number_min: "No. identitas minimal 16 karakter",
        identity_number_max: "No. identitas maksimal 16 karakter",
        transfer_required: "Jumlah uang harus diisi",
        transfer_min: "Minimal jumlah transfer adalah Rp. 50.000",
        transfer_max: "Maksimal jumlah transfer adalah Rp. 2.500.000",
        transfer_min_alfa: "Minimal jumlah transfer adalah Rp. 10.000",
        transfer_max_alfa: "Maksimal jumlah transfer adalah Rp. 5.000.000",
        amount_required: "Jumlah uang harus diisi",
        pin_length: "Minimum 6 karakter",
        old_pin_required: "PIN lama diperlukan",
        company_required: "Perusahaan wajib diisi.",
        citizenship_required: "Kewarganegaraan wajib dipilih.",
        country_required: "Negara wajib dipilih.",
        fullname_required: "Nama Lengkap wajib diisi.",
        pob_required: "Tempat Lahir wajib diisi.",
        dob_required: "Tanggal Lahir wajib diisi.",
        gender_required: "Jenis Kelamin wajib dipilih.",
        address_required: "Alamat wajib diisi.",
        neighborhood_required: "RT/RW wajib diisi.",
        subdistrict_required: "Kecamatan wajib dipilih.",
        district_required: "Kabupaten wajib dipilih.",
        village_required: "Kelurahan wajib dipilih.",
        province_required: "Provinsi wajib dipilih.",
        occupation_required: "Pekerjaan wajib dipilih.",
        businessfields_required: "Bidang Pekerjaan wajib diisi.",
        position_required: "Jabatan wajib diisi.",
        companyaddr_required: "Alamat Tempat Kerja wajib diisi.",
        monthlyincome_required: "Pendapatan Bulanan wajib diisi.",
        withdraw1: "Sumber dana harus dipilih",
        withdraw2: "Tujuan transaksi pengiriman harus diisi",
        withdraw3: "Negara harus dipilih",
        withdraw4: "Nama toko harus dipilih",
        localityOfReceiver_alfa: "Bank tujuan harus dipilih",
        sourceOfReceiver_alfa: "No Rekening tujuan harus diisi",
    },
    otp: {
        send_via_email: "Kode verifikasi telah dikirimkan melalui Email ke ",
        send_via_phone: "Kode verifikasi telah dikirimkan melalui SMS ke ",
        send_via_whatsapp: "Kode verifikasi telah dikirimkan melalui WhatsApp ke ",
    },
    confirm_identity: {
        identity_number: "Nomor KTP",
        full_name: "Nama Lengkap",
        place_of_birth: "Tempat Lahir",
        date_of_birth: "Tanggal Lahir",
        gender: "Jenis Kelamin",
        address: "Alamat",
        address_domisili: "Alamat Domisili",
        neighborhood: "RT/RW",
        sub_district: "Kecamatan",
        village: "Kelurahan / Desa",
        district: "Kota/Kabupaten",
        province: "Provinsi",
        occupation: "Pekerjaan",
        citizenship: "Kewarganegaraan",
        country: "Negara"
    },
    transaction: {
        transaction: "TRANSAKSI",
        status_1: "Menunggu Disetor",
        status_2: "Sudah Disetor",
        status_3: "Sudah Diambil",
        status_4: "Dibatalkan",
        transaction_detail: "Detail Transaksi",
        transaction_date: "Tanggal Transaksi",
        money_amount: "Jumlah Uang",
        recipient_name: "Nama Penerima",
        recipient_no: "No Id/No Hp Penerima",
        recipient_no2: "No. Hp Penerima",
        recipient_no3: "No. Id Penerima",
        mtcn_code: "Kode MTCN",
        cancellation_code: "Kode Pembatalan",
        money_deposit_locations: "Lokasi Setor Uang",
        money_cancel_locations: "Lokasi Pembatalan Uang",
        store_name: "Nama Toko",
        money_withdrawal_locations: "Lokasi Tarik Uang",
        alert1: "Segera lakukan setor uang ke lokasi penyetoran. Untuk dapat melihat detail transaksi ini kembali, silahkan akses menu Transaksi.",
        withdraw_mtcn_code: "Kode Penarikan Uang (MTCN)",
        r_money_amount: "Jumlah uang yang diterima",
        input_pin_trx: "Masukan PIN Transaksi",
        input_pin: "Masukan 6 digit PIN transaksi Anda",
        w_input_pin: "PIN yang Anda masukan salah. Silahkan coba kembali",
        depo_loc: "Lokasi Setor",
        withdrawal_loc: "Lokasi Tarik",
        cancel_mtcn_code: "Kode Pembatalan Uang",
        trans_date: "Tanggal Pengiriman",
        trans_notice1: "Belum ada transaksi",
        trans_notice2: "Transaksi yang Anda lakukan akan muncul disini",
    },
    profile: {
        change_data: "Ubah Data",
        email: "Email",
        phone: "No. Handphone",
        password: "Kata Sandi",
        forgot_pin: "Lupa PIN?",
        forgot_pin2: "Lupa PIN",
        not_verif: "belum terverifikasi",
        already_verif: "sudah terverifikasi",
        verified: "Terverifikasi",
        verification: "Verifikasi",
    },
    forgot_pass: {
        label1: "Gunakan Email atau Nomor HP untuk Ubah Password Anda",
        label2: "Kami akan mengirimkan",
        label3: "Kode Verifikasi",
        label4: "ke Email atau Nomor Handphone Anda",
        label5: "Ubah Kata Sandi",
        label6: "Gunakan Email atau Nomor HP untuk Ubah PIN Anda",
    },
    pin: {
        old_pin: "PIN Lama",
        new_pin: "PIN Baru",
        confirmnew_pin: "Konfirmasi PIN Baru",
    },
    rc: {
        "0000": "Sukses",
        "0005": "Terjadi Kesalahan Validasi",
        "0006": "Terjadi kesalahan saat mengambil data pengguna",
        "0007": "No Handphone/Email belum terdaftar, silahkan coba kembali",
        "0008": "Pengecualian umum",
        "0009": "Terjadi kesalahan saat memasukkan data pengguna ke dalam database",
        "0010": "Terjadi kesalahan saat memasukkan akun ke database",
        "0011": "Terjadi kesalahan saat men-decode",
        "0012": "Error saat memasukkan transaksi deposit",
        "0013": "JWKS tidak ditemukan",
        "0014": "Private-Key tidak ditemukan",
        "0015": "Terjadi kesalahan saat mengambil transaksi deposit",
        "0016": "Transaksi deposit tidak ditemukan",
        "0017": "Kesalahan saat mengirim email",
        "0018": "Error saat memasukkan OTP untuk registrasi",
        "0019": "Error when doing query context on count OTP record list",
        "0020": "Kesalahan saat melakukan pemindaian pada daftar catatan OTP",
        "0021": "Kesalahan saat memperbarui OTP ke database",
        "0022": "Kode OTP yang Anda masukkan salah",
        "0023": "Kesalahan saat mengambil jumlah pengguna dari database",
        "0024": "Kesalahan saat pemindaian hitungan pengguna",
        "0025": "No Handphone/Email sudah terdaftar, silahkan gunakan No/Email yang belum terdaftar",
        "0026": "Error saat mengubah status OTP",
        "0027": "Kesalahan saat memperbarui akun",
        "0028": "PIN salah, silahkan coba kembali",
        "0029": "Kesalahan saat memasukkan data",
        "0030": "Kesalahan saat memperbaharui data",
        "0031": "Kesalahan saat menghapus data",
        "0032": "OTP telah kadaluarsa",
        "0033": "OTP sudah digunakan",
        "0034": "Kesalahan saat query menghitung daftar hitam",
        "0035": "Error Nominal Deposit Melebihi 7.500.000 per hari",
        "0036": "Error Nominal Deposit Melebihi 2.500.000 per transaksi",
        "0037": "Kesalahan saat memperbarui deposit MTCN",
        "0038": "Kesalahan saat memperbarui status deposit",
        "0039": "Kesalahan saat mengatur PIN",
        "0040": "Kesalahan saat ubah PIN",
        "0041": "Error tidak dapat memblokir pin",
        "0042": "Kesalahan, pengguna belum memiliki pin",
        "0043": "Kesalahan umum pada database",
        "0044": "Kode Penarikan tidak ditemukan",
        "0045": "Kode Penarikan sudah digunakan",
        "0046": "Kode Penarikan tidak valid/kadaluarsa",
        "0047": "Error kode pembatalan tidak ditemukan",
        "0048": "Error kode pembatalan sudah digunakan",
        "0049": "Error kode pembatalan tidak valid/kedaluwarsa",
        "0050": "Error profil tidak ditemukan",
        "0051": "Error saat mengambil profil dari database",
        "0052": "Error saat mengambil jumlah blokir OTP",
        "0053": "OTP melebihi batas maksimal, silahkan coba lagi besok",
        "0054": "Error saat mengkonversi waktu",
        "0055": "Error saat memperbarui jumlah blokir OTP",
        "0056": "Untuk melakukan transaksi berikutnya, mohon tunggu sampai akun Anda terverifikasi.",
        "0057": "Error saat melakukan pemindaian hitungan daftar hitam",
        "0058": "Error saat pengguna ada dalam daftar hitam",
        "0059": "Error saat memperbarui pengguna ke database",
        "0060": "Akun anda terblokir, silahkan hubungi CS kami di 50127667",
        "0061": "Kata sandi salah, silahkan coba kembali",
        "0062": "Error saat memperbarui status registrasi OTP tidak ada baris yang terpengaruh",
        "0063": "Error saat mengunggah file ke cloud",
        "0064": "Error pembaruan status dilarang",
        "0065": "Error saat memeriksa OTP lupa kata sandi",
        "0066": "Kata sandi salah, silahkan coba kembali",
        "0067": "Error saat memperbarui kata sandi",
        "0068": "Error saat mengirim telepon",
        "0069": "Email belum terverifikasi, mohon lakukan verifikasi email",
        "0070": "Data tidak ditemukan",
        "0071": "User sudah memiliki PIN",
        "0072": "Anda tidak dapat menggunakan Kode Penarikan ini",
        "0073": "Kode Pembatalan tidak dapat digunakan",
        "0074": "Email sudah digunakan",
        "0075": "Error saat memindai email yang sudah digunakan",
        "0076": "Nomor telepon sudah terdaftar",
        "0077": "Error ketika memindai nomor telepon yang sudah ada",
        "0078": "Error transaksi melebihi 3 transaksi per hari",
        "0079": "Error email/nomor telepon tidak sesuai dengan yang terdaftar",
        "0080": "Error saat aktivasi KTP",
        "0081": "Mohon untuk aktivasi KTP terlebih dahulu untuk melakukan transaksi",

        "0082": "Kegagalan saat mendapatkan daftar posisi karyawan ke database",
        "0083": "Kegagalan saat memasukkan posisi karyawan ke database",
        "0084": "Kegagalan saat memperbarui posisi karyawan ke database",
        "0085": "Kegagalan saat mendapatkan daftar bidang bisnis ke database",
        "0086": "Kegagalan saat memasukkan bidang bisnis ke database",
        "0087": "Kegagalan saat memperbarui bidang bisnis ke database",
        "0088": "Kegagalan saat memasukkan log aktivitas ke database",
        "0089": "Kegagalan Kode produk sudah ada",
        "0090": "Kegagalan saat mendapatkan daftar blacklist ke database",
        "0091": "Kegagalan saat memasukkan daftar hitam ke database",
        "0092": "Kegagalan saat memperbarui daftar hitam ke database",
        "0093": "Error Respons Http",
        "0094": "Nomor KTP sudah terpakai",
        "0095": "Error when get account query context",
        "0096": "Error when get account scan",
    }
}