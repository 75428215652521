export const en = {
    general: {
        send: "Send",
        withdraw: "Withdraw",
        save: "Save",
        logout: "Logout",
        logout_notice: "Logout from Remid",
        statement1: "By registering an account, I agree to the",
        statement2: " Terms and Conditions ",
        statement3: "that apply.",
        signup_header: "Verification Successful",
        signup_subheader: "Your account has been successfully created. Sign in to start using Remid.",
        form_signup1: "Create Account Now!",
        form_signup2: "Sign up to use Remid",
        form_signup3: "Already have an account?",
        form_moneytf1: "Money Transfer Form",
        form_moneytf2: "Recipient's Full Name",
        form_moneytf3: "Recipient's Identification Number",
        form_moneytf4: "Recipient's Phone Number",
        form_moneytf5: "The amount of money sent",
        form_moneytf6: "Sender's Source of Funds",
        form_moneytf7: "Transfer Purpose",
        form_chngphone1: "New Phone Number",
        overlay1: "Recipient Data Confirmation",
        overlay2: "Make sure the Recipient Data, Name, Identification Number/KTP, and Phone Number are correct",
        overlay3: "Your Session Has Expired",
        overlay4: "Please log in again to continue your action.",
        title1: "Change Email",
        title2: "Change Phone Number",
        title3: "Change PIN",
        title4: "Set PIN",
        title5: "Verification",
        btn1: "CHANGE",
        header1: "Successfully Changed!",
        header2: "Successfully Changed!",
        subheader1: "Congratulations, your phone number has been successfully changed.",
        subheader2: "Congratulations, your password has been successfully changed!",
        subheader3: "Congratulations, your PIN has been successfully changed!",
        subheader4: "Congratulations, your PIN has been successfully created!",
        showqr: "Show the QR Code to the Alfamart staff.",
        close: "CLOSE",
        general_error: "General Error",
        register_error: "Register Error",
        failed1: "Failed",
        failed2: "To make the next transaction, please wait until your account is verified.",
    },
    auth: {
        email: "Email",
        phone: "Phone Number",
        username: "Phone Number/Email",
        password: "Password",
        old_password: "Old Password",
        forgot_password: "Forgot Password",
        sign_in: "Sign In",
        helper_text_password: "Minimum of 8 Characters including alphabets (A-z), Numbers (0-9), and symbols",
        register: "Sign Up",
        confirm_password: "Confirm Password",
        header: "Welcome!",
        sub_header: "Sign in to start using Remid",
        do_not_have_account: "Do not have account?",
        success_sign_in_header: "Sign In Successful",
        success_sign_in_subheader: 'Congratulations, you have successfully signed in. Click "Continue" to go to the main page.',
        next_text: "Continue",
        cancel: "Cancel",
        back: "BACK",
        success: "Success",
        helper_text_password2: "Minimum 8 characters",
        helper_text_password3: "Set New Password",
        news: "Do you want to know the Remid update?",
        news_detail: "Look Here",
    },
    home: {
        header: "Welcome!",
        sub_header: "Try all of Remid's features now."
    },
    bottom_navigation: {
        home: "Home",
        transaction: "Transaction",
        profile: "Profile",
        version: "Version 1.0.0",
    },
    operations: {
        operation: "Menu Product",
        send_money: "Money Transfer",
        withdraw_money: "Money Withdrawal",
        cancel_money: "Money Cancellation"
    },
    validation: {
        email_format: "Wrong email format, example: rem@remid.com",
        phone_required: "Phone number is required",
        phone_format: "Invalid phone number format",
        phone_short: "Phone number too short, minimum 10 characters",
        phone_long: "Phone number too long, maksimum 14 characters",
        password_short: "Minimum 8 characters",
        password_combination: "Combination of letters and numbers",
        password_required: "Password is required",
        password_match: "Password and password confirmation match",
        name_required: "Name is required",
        identity_number_required: "No. ID must be filled",
        identity_number_min: "No. ID of at least 16 characters",
        identity_number_max: "No. ID maximum 16 characters",
        transfer_required: "Amount of money must be filled",
        transfer_min: "The minimum transfer amount is Rp. 50,000",
        transfer_max: "The maximum transfer amount is Rp. 2,500,000",
        transfer_min_alfa: "The minimum transfer amount is Rp. 10,000",
        transfer_max_alfa: "The maximum transfer amount is Rp. 5,000,000",
        amount_required: "Amount of money must be filled",
        pin_length: "Minimum 6 characters",
        old_pin_required: "PIN lama diperlukan",
        company_required: "Company must be filled.",
        citizenship_required: "Citizenship must be chosen.",
        country_required: "Country must be chosen.",
        fullname_required: "Full name is required.",
        pob_required: "Place of Birth is required.",
        dob_required: "Date of Birth is required.",
        gender_required: "Gender is required.",
        address_required: "Address is required.",
        neighborhood_required: "Neighborhood is required.",
        subdistrict_required: "Sub District is required.",
        district_required: "District is required.",
        village_required: "Village is required.",
        province_required: "Province is required.",
        occupation_required: "Occupation is required.",
        businessfields_required: "Business Fields is required.",
        position_required: "Position is required.",
        companyaddr_required: "Company Address is required.",
        monthlyincome_required: "Monthly Income is required.",
        withdraw1: "Source of fund must be selected.",
        withdraw2: "Transfer purpose must be filled in.",
        withdraw3: "The country must be selected",
        withdraw4: "The store name must be selected",
        localityOfReceiver_alfa: "The destination bank must be selected",
        sourceOfReceiver_alfa: "The destination account number must be filled in",
    },
    otp: {
        send_via_email: "Verification code has been sent via Email to ",
        send_via_phone: "Verification code has been sent via SMS to ",
        send_via_whatsapp: "Verification code has been sent via WhatsApp to ",
    },
    confirm_identity: {
        identity_number: "ID card number",
        full_name: "Full name",
        place_of_birth: "Place of birth",
        date_of_birth: "Date of birth",
        gender: "Gender",
        address: "Address",
        address_domisili: "Domisili Address",
        neighborhood: "RT/RW",
        sub_district: "Subdistrict",
        village: "Village",
        district: "District",
        province: "Province",
        occupation: "Occupation",
        citizenship: "Citizenship",
        country: "Country"
    },
    transaction: {
        transaction: "TRANSACTION",
        status_1: "Waiting for Deposit",
        status_2: "Deposited",
        status_3: "Already taken",
        status_4: "Canceled",
        transaction_detail: "Transaction Detail",
        transaction_date: "Transaction Date",
        money_amount: "Amount",
        recipient_name: "Recipient's name",
        recipient_no: "Recipient's ID No./Phone number",
        recipient_no2: "Recipient's Phone Number",
        recipient_no3: "Recipient ID Number",
        mtcn_code: "MTCN Code",
        cancellation_code: "Cancellation Code",
        money_deposit_locations: "Money Deposit Locations",
        money_cancel_locations: "Money Cancellation Location",
        store_name: "Store Name",
        money_withdrawal_locations: "Money Withdrawal Locations",
        alert1: "Please deposit the money to the deposit location immediately. To view the details of this transaction again, please access the Transactions menu.",
        withdraw_mtcn_code: "Money Transfer Control Number (MTCN)",
        r_money_amount: "Received amount of money",
        input_pin_trx: "Enter Transaction PIN",
        input_pin: "Enter your 6-digit transaction PIN",
        w_input_pin: "The PIN you entered is incorrect. Please try again.",
        depo_loc: "Deposit Location",
        withdrawal_loc: "Withdrawal Location",
        cancel_mtcn_code: "Money Transfer Cancellation Number (MTCN)",
        trans_date: "Transfer Date",
        trans_notice1: "No transactions yet",
        trans_notice2: "Your transactions will appear here.",
    },
    profile: {
        change_data: "Change Data",
        email: "Email",
        phone: "Mobile No.",
        password: "Password",
        forgot_pin: "Forgot PIN?",
        forgot_pin2: "Forgot PIN",
        not_verif: "not verified",
        already_verif: "already verified",
        verified: "Verified",
        verification: "Verification",
    },
    forgot_pass: {
        label1: "Use Email or Mobile Number to Change Your Password",
        label2: "We will send",
        label3: "a Verification Code",
        label4: "to your Email or Mobile Number",
        label5: "Change Password",
        label6: "Use Email or Phone Number to Change Your PIN",
    },
    pin: {
        old_pin: "Old PIN",
        new_pin: "New PIN",
        confirmnew_pin: "Confirm New PIN",
    },
    rc: {
        "0000": "Success",
        "0005": "Error validation",
        "0006": "Error when get user",
        "0007": "User Not Found",
        "0008": "General exception",
        "0009": "Error when insert user to database",
        "0010": "Error when insert account",
        "0011": "Error when decode",
        "0012": "Error when insert deposit transaction",
        "0013": "Cannot find JWKS",
        "0014": "Cannot find private key",
        "0015": "Error when get deposit transaction",
        "0016": "Error transaction deposit not found",
        "0017": "Error when send email",
        "0018": "Error when inserting OTP for registration",
        "0019": "Error when doing query context on count OTP record list",
        "0020": "Error when doing scan on count OTP record list",
        "0021": "Error when update OTP to database",
        "0022": "Error OTP not exist",
        "0023": "Error when query count user",
        "0024": "Error when scanning count user",
        "0025": "Error user exist",
        "0026": "Error when update OTP status",
        "0027": "Error when update account",
        "0028": "Error Pin Incorrect",
        "0029": "Error while Insert Data",
        "0030": "Error while Update Data",
        "0031": "Error while Delete Data",
        "0032": "Error OTP expired",
        "0033": "Error OTP is used",
        "0034": "Error while query count blacklist",
        "0035": "Error Nominal Deposit Exceed 7.500.000 per day",
        "0036": "Error Nominal Deposit Exceed 2.500.000 per transaction",
        "0037": "Error while update mtcn deposit",
        "0038": "Error while update status deposit",
        "0039": "Error while set pin",
        "0040": "Error while change pin",
        "0041": "Error can not block pin",
        "0042": "Error user doesnt have pin yet",
        "0043": "Error general database",
        "0044": "Error MTCN not found",
        "0045": "Error MTCN already used",
        "0046": "Error MTCN Invalid/expired",
        "0047": "Error cancellation code not found",
        "0048": "Error cancellation code already used",
        "0049": "Error cancellation code Invalid/expired",
        "0050": "Error profile not found",
        "0051": "Error when get profile from database",
        "0052": "Error when get OTP block count",
        "0053": "Error OTP exceeded",
        "0054": "Error when convert time",
        "0055": "Error when update OTP block count",
        "0056": "To make the next transaction, please wait until your account is verified.",
        "0057": "Error when scan count blacklist",
        "0058": "Error when user exist in blacklist",
        "0059": "Error when update user to database",
        "0060": "Error user blocked, Please contact our CS at 50127667",
        "0061": "Error wrong password",
        "0062": "Error when update OTP register status no rows affected",
        "0063": "Error when upload file to cloud",
        "0064": "Error update status forbiden",
        "0065": "Error when check OTP forgot password",
        "0066": "Error Password Incorrect",
        "0067": "Error when update password",
        "0068": "Error when send phone",
        "0069": "Error email not verified",
        "0070": "Error Data not found",
        "0071": "Error user already have pin",
        "0072": "Error user can not withdraw this transaction",
        "0073": "Error user can not reversal this transaction",
        "0074": "Error email is exist",
        "0075": "Error when scan email exist",
        "0076": "Phone number already exists",
        "0077": "Error when scan phone exist",
        "0078": "Error Transaction exceed 3 transaction per day",
        "0079": "Error email/phone doesn't match what is registered",
        "0080": "Error when activate ID Card",
        "0081": "Please activate your ID card (KTP) first before conducting any transactions.",

        "0082": "Error when get list employee position to database",
        "0083": "Error when insert employee position to database",
        "0084": "Error when update employee position to database",
        "0085": "Error when get list business field to database",
        "0086": "Error when insert business field to database",
        "0087": "Error when update business field to database",
        "0088": "Error insert activity log to database",
        "0089": "Error Product code has exist",
        "0090": "Error when get list blacklist to database",
        "0091": "Error when insert blacklist to database",
        "0092": "Error when update blacklist to database",
        "0093": "Error Http Response",
        "0094": "Error id card is exist",
        "0095": "Error when get account query context",
        "0096": "Error when get account scan",
    }
}