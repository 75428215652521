import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuCard from "config/components/menu-card";
import useLanguage from "config/hooks/useLanguage";
import { useTheme, Box, Stack, Typography } from "@mui/material";
import { ReactComponent as RemidIcon } from "assets/svg/RemidSmallLogo.svg";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import CancelScheduleSendRoundedIcon from "@mui/icons-material/CancelScheduleSendRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import { ReactComponent as CashtoAccount } from "assets/svg/CashtoAccount.svg";
import useCheckVerif from "config/hooks/useCheckVerified";
import HomeUseCase from "domain/interactor/Home";
import HomeApi from "data/Home";

const HomeContent: React.FC = () => {
    useCheckVerif(new HomeUseCase(new HomeApi));

    const { palette } = useTheme();

    const { t } = useLanguage();

    const navigate = useNavigate();

    const dummyMenu = [
        {
            text: t("operations.send_money"),
            icon: <SendRoundedIcon fontSize="large" />,
            onClick: () => navigate("/operation/transfer-uang"),
        },
        {
            text: t("operations.withdraw_money"),
            icon: <CompareArrowsRoundedIcon fontSize="large" />,
            onClick: () => navigate("/operation/withdraw-uang"),
        },
        {
            text: t("operations.cancel_money"),
            icon: <CancelScheduleSendRoundedIcon fontSize="large" />,
            onClick: () => navigate("/operation/cancel-transfer-uang"),
        },
    ];

    const dummyMenu2 = [
        // {
        //     text: t("operations.send_money"),
        //     icon: <SendRoundedIcon fontSize="large" />,
        //     onClick: () => navigate("/operation/transfer-uang"),
        // },
        {
            text: "Kirim Uang Alfamart",
            icon: <SendRoundedIcon fontSize="large" />,
            onClick: () => navigate("/operation/transfer-uang-alfa"),
        },
    ];

    const [selectedMenu, setSelectedMenu] = useState(dummyMenu);

    const [isCashToCashClicked, setCashToCashClicked] = useState(true);
    const [isCashToAccountClicked, setCashToAccountClicked] = useState(false);

    const handleCashToCashClick = () => {
        setSelectedMenu(dummyMenu);
    };

    const handleCashToAccountClick = () => {
        //setSelectedMenu(dummyMenu2);
    };

    // ASLI
    // return (
    //     <Box sx={{ width: "100%", height: "100%", background: "white" }}>
    //         <Box
    //             sx={{
    //                 width: "100%",
    //                 height: "20vh",
    //                 minHeight: 160,
    //                 background: palette.primary.main,
    //                 backgroundImage: "url('/svg/HomePageLine.svg')",
    //                 backgroundSize: "100%",
    //                 backgroundRepeat: "no-repeat",
    //                 backgroundPosition: "left top",
    //             }}
    //         >
    //             <Stack
    //                 justifyContent="space-evenly"
    //                 sx={{
    //                     height: "100%",
    //                     p: "1em",
    //                 }}
    //             >
    //                 <Stack direction="row" justifyContent="space-between">
    //                     <RemidIcon />
    //                     {/* buka dulu */}
    //                     {/* <LanguageSelector /> */}
    //                 </Stack>
    //                 <Stack sx={{ flex: 1, justifyContent: "center" }}>
    //                     <Typography
    //                         sx={{
    //                             fontSize: "1.25rem",
    //                             fontWeight: 700,
    //                             color: "#fff",
    //                         }}
    //                     >
    //                         {t("home.header")}
    //                     </Typography>
    //                     <Typography
    //                         sx={{
    //                             fontSize: "0.675rem",
    //                             fontWeight: 400,
    //                             color: "#fff",
    //                         }}
    //                     >
    //                         {t("home.sub_header")}
    //                     </Typography>
    //                 </Stack>
    //             </Stack>
    //         </Box>

    //         <Box
    //             sx={{
    //                 position: "relative",
    //                 bottom: 12,
    //                 px: "1rem",
    //                 pb: "3rem",
    //                 borderRadius: "16px 16px 0px 0px",
    //                 background: "white",
    //             }}
    //         >
    //             <Typography sx={{ fontWeight: 700, py: "1em" }}>
    //                 Menu
    //             </Typography>

    //             <Stack
    //                 justifyContent="center"
    //                 alignItems="center"
    //                 sx={{
    //                     width: 100,
    //                 }}
    //                 spacing={1}
    //             >
    //                 <Box
    //                     sx={{
    //                         color: palette.secondary.main,
    //                     }}
    //                 >
    //                     <AccountBalanceWalletRoundedIcon fontSize="large" />
    //                 </Box>
    //                 <Typography sx={{ fontSize: ".875rem" }}>
    //                     Cash to Cash
    //                 </Typography>
    //             </Stack>

    //             <Typography sx={{ fontWeight: 700, py: "1em" }}>
    //                 {t("operations.operation")}
    //             </Typography>

    //             <Box
    //                 sx={{
    //                     overflow: "auto",
    //                     display: "grid",
    //                     gridTemplateColumns: {
    //                         xs: "repeat(2, auto)",
    //                         md: "repeat(3, auto)",
    //                     },
    //                     gap: "1rem",
    //                     p: ".3rem",
    //                 }}
    //             >
    //                 {dummyMenu.map(({ icon, text, onClick }, idx) => {
    //                     return (
    //                         <MenuCard
    //                             key={idx}
    //                             icon={icon}
    //                             text={text}
    //                             onClick={onClick}
    //                         />
    //                     );
    //                 })}
    //             </Box>
    //         </Box>
    //     </Box>
    // );

    return (
        <Box sx={{ width: "100%", height: "100%", background: "white" }}>
            <Box
                sx={{
                    width: "100%",
                    height: "20vh",
                    minHeight: 160,
                    background: palette.primary.main,
                    backgroundImage: "url('/svg/HomePageLine.svg')",
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left top",
                }}
            >
                <Stack
                    justifyContent="space-evenly"
                    sx={{
                        height: "100%",
                        p: "1em",
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <RemidIcon />
                        {/* buka dulu */}
                        {/* <LanguageSelector /> */}
                    </Stack>
                    <Stack sx={{ flex: 1, justifyContent: "center" }}>
                        <Typography
                            sx={{
                                fontSize: "1.25rem",
                                fontWeight: 700,
                                color: "#fff",
                            }}
                        >
                            {t("home.header")}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.675rem",
                                fontWeight: 400,
                                color: "#fff",
                            }}
                        >
                            {t("home.sub_header")}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>

            <Box
                sx={{
                    position: "relative",
                    bottom: 12,
                    px: "1rem",
                    pb: "3rem",
                    borderRadius: "16px 16px 0px 0px",
                    background: "white",
                }}
            >
                <Typography sx={{ fontWeight: 700, py: "1em" }}>
                    Produk Remittance
                </Typography>

                <Box
                    sx={{
                        overflow: "auto",
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(2, 150px)",
                            md: "repeat(2, 150px)",
                        },
                        gap: "1rem",
                        p: ".3rem",
                    }}
                >
                    <MenuCard
                        text="Kirim dan Tarik Tunai"
                        icon={<AccountBalanceWalletRoundedIcon />}
                        onClick={() => {
                            handleCashToCashClick();
                            setCashToCashClicked(true);
                            setCashToAccountClicked(false);
                        }}
                        sx={{
                            boxShadow: "none",
                            backgroundColor: isCashToCashClicked ? '#EEEEEE' : 'white',
                            cursor: isCashToCashClicked ? 'default' : 'pointer',
                        }}
                    />
                    <MenuCard
                        text="Kirim Tunai ke Rekening"
                        icon={<CashtoAccount />}
                        onClick={() => {
                            handleCashToAccountClick();
                            setCashToCashClicked(false);
                            setCashToAccountClicked(true);
                        }}
                        sx={{
                            boxShadow: "none",
                            backgroundColor: isCashToAccountClicked ? '#EEEEEE' : 'white',
                            cursor: isCashToAccountClicked ? 'default' : 'pointer',
                        }}
                    />
                </Box>

                <Typography sx={{ fontWeight: 700, py: "1em" }}>
                    {t("operations.operation")}
                </Typography>

                <Box
                    sx={{
                        overflow: "auto",
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(2, auto)",
                            md: "repeat(3, auto)",
                        },
                        gap: "1rem",
                        p: ".3rem",
                    }}
                >
                    {selectedMenu.map(({ icon, text, onClick }, idx) => {
                        return (
                            <MenuCard
                                key={idx}
                                icon={icon}
                                text={text}
                                onClick={onClick}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default HomeContent;
