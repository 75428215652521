import React from 'react';
import {
    Box,
    Divider,
    Typography,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Stack,
} from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { TransactionData } from 'domain/entity/Auth/model/Transaction'
import useLanguage from 'config/hooks/useLanguage';
import currencyFormatter from 'config/shared/utils/currency-formatter';

interface Item {
    id: number;
    date: Date;
    text: string;
    status: string;
}

interface Props {
    items: TransactionData[];
    onClick: (id: string) => void;
}

const List: React.FC<Props> = ({ items, onClick }) => {
    let currentDate: Date | null = null;

    const { t } = useLanguage();

    const getBackgroundColor = (text: string): string => {
        switch (text) {
            case 'Dibatalkan':
                return 'linear-gradient(270deg, #E91C2E 0%, #F1717C 100%);';
            case 'Menunggu Scan':
            case 'Menunggu disetor':
                return 'linear-gradient(270deg, #EBA440 0%, #FDCA82 100%);';
            default:
                return 'linear-gradient(270deg, #2E7D32 0%, rgba(46, 125, 50, 0.6) 100%);';
        }
    };

    const groupedItems = items.reduce<{ [key: string]: TransactionData[] }>((acc, item) => {
        console.log('tanggal', item.transactionDate)
        const date = new Date(item.transactionDate).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric' });
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(item);
        return acc;
    }, {});

    const sortedGroupedItems = Object.entries(groupedItems).sort(([a], [b]) => {
        // di sort tanggal terbaru paling atas list
        const dateA = new Date(a);
        const dateB = new Date(b);
        console.log(dateA, dateB)
        return dateB.getTime() - dateA.getTime();
    });

    const navigate = useNavigate();

    const handleClick = (item: any) => {
        console.log(item);
        onClick(item);
    };

    return items.length ? (
        <>
            {sortedGroupedItems.map(([date, group]) => (
                <Box key={date} sx={{ fontFamily: 'Poppins' }}>
                    <Box sx={{ padding: '10px 15px', background: 'rgba(0, 0, 0, 0.06)', fontFamily: 'Poppins' }}>{date}</Box>
                    <Box sx={{ padding: '0 15px' }}>
                        {group.map((item, index) => (
                            <React.Fragment key={item.transDepositId}>
                                <Box key={item.transDepositId} onClick={() => handleClick(item)}
                                    sx={{
                                        height: '50px'
                                        , display: 'flex'
                                        , alignItems: 'center'
                                        , padding: '12px 0px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '80%',
                                        }}
                                    >
                                        <Typography sx={{
                                            flex: '1'
                                            , textAlign: 'left'
                                            , fontSize: '12px'
                                            , fontFamily: 'Poppins'
                                            , fontWeight: '400'
                                            , color: '#00000099'
                                        }}>
                                            {item.product}
                                        </Typography>
                                        <Typography sx={{
                                            flex: '1'
                                            , textAlign: 'left'
                                            , fontSize: '16px'
                                            , fontWeight: '600'
                                            , fontFamily: 'Poppins'
                                        }}>
                                            {item.transDepositId}
                                        </Typography>
                                        <Typography sx={{
                                            textAlign: 'start',
                                            fontSize: '14px',
                                            fontFamily: 'Poppins',
                                            color: '#2E7D32',
                                            fontWeight: 'bold'
                                        }}>
                                            {currencyFormatter(item.amount)}
                                        </Typography>
                                    </Box>
                                    <Typography sx={{
                                        width: '35%',
                                        textAlign: 'center',
                                        background: getBackgroundColor(item.status),
                                        borderRadius: '10px',
                                        color: '#FFF',
                                        fontSize: '12px',
                                        fontFamily: 'Poppins',
                                    }}>
                                        {item.status}
                                    </Typography>
                                </Box>
                                {index !== items.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
            ))
            }
        </>
    ) : (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="15px"
            >
                <Box my={3}>
                    <img src="/svg/transaction_img.svg" alt="Placeholder image" />
                </Box>
                <Typography variant="h6"
                    sx={{
                        fontWeight: '600'
                    }}
                >
                    {t("transaction.trans_notice1")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '400'
                    }}
                >
                    {t("transaction.trans_notice2")}
                </Typography>
            </Box>
        </>
    );
};

export default List;
