import { GlobalResourceResponse } from "domain/entity";
import { BankRequest, BankResponse } from "domain/entity/Bank";
import BankRepository from "domain/repository/Bank";

export default class BankUseCase implements BankRepository {
    constructor(private repository: BankRepository) { }

    getBank({...rest}: BankRequest): Promise<BankResponse> {
        return this.repository.getBank({...rest})
    }
}